fetch('data.json')
  .then(response => response.json())
  .then(drawings => {

    // Smooth scroll ///////////////////////////////////////////////////////////////
    const navItems = document.querySelectorAll('.main-nav-item-link');
    navItems.forEach(item => item.addEventListener('click', event => {
      event.preventDefault();
      const url = new URL(window.location);
      url.hash = event.target.hash; const anchor = document.querySelector(event.target.hash);
      window.history.pushState({}, '', url); anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }));

    // Theme toggle ////////////////////////////////////////////////////////////////
    const toggleSwitch = document.querySelector('.theme-switch-wrap input[type="checkbox"]');
    let theme = localStorage.getItem('theme');
    if (!theme) {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      theme = prefersDark ? 'dark' : 'light';
    }
    const toggleTheSwitch = () => {
      theme = toggleSwitch.checked ? 'dark' : 'light';
      document.documentElement.setAttribute('data-theme', theme);
      localStorage.setItem('theme', theme);
    };
    document.documentElement.setAttribute('data-theme', theme);
    toggleSwitch.checked = theme === 'dark';
    toggleSwitch.addEventListener('change', toggleTheSwitch);

    // Prefence change /////////////////////////////////////////////////////////////
    const switchToDarkTheme = () => {
      document.documentElement.setAttribute('data-theme', 'dark');
      toggleSwitch.checked = true;
    };
    const switchToLightTheme = () => {
      document.documentElement.setAttribute('data-theme', 'light');
      toggleSwitch.checked = false;
    };
    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => event.matches && switchToDarkTheme());
    window.matchMedia('(prefers-color-scheme: light)')
      .addEventListener('change', event => event.matches && switchToLightTheme());

    // Add drawings with template //////////////////////////////////////////////////
    const drawingList = document.querySelector('.drawing-items');
    const template = document.querySelector('#drawing-item-template');

    const makeItem = drawing => {
      const clone = template.content.cloneNode(true);
      clone.querySelector('a')
        .href = `images/watermarked/${drawing.slug}-watermarked.jpg`;
      clone.querySelector('source[type="image/webp"]')
        .srcset = `
      images/thumbs/${drawing.slug}-300.webp 1x,
      images/thumbs/${drawing.slug}-600.webp 2x,
      images/thumbs/${drawing.slug}-900.webp 3x
    `;
      clone.querySelector('source[type="image/jpeg"]')
        .srcset = `
      images/thumbs/${drawing.slug}-300.jpg 1x,
      images/thumbs/${drawing.slug}-600.jpg 2x,
      images/thumbs/${drawing.slug}-900.jpg 3x
    `;
      clone.querySelector('img')
        .src = `images/thumbs/${drawing.slug}-300.jpg`;
      clone.querySelector('img')
        .alt = drawing.title;
      clone.querySelector('.info-cell__title')
        .textContent = drawing.title;
      clone.querySelector('.info-cell__price')
        .textContent = '$' + drawing.price;
      clone.querySelector('.info-cell__dimensions')
        .textContent = drawing.dimensions;
      clone.querySelector('.info-cell__description')
        .textContent = drawing.description;
      clone.querySelector('.info-cell__date')
        .textContent = drawing.date;
      clone.querySelector('input[name="hosted_button_id"]')
        .value = drawing.paypal;
      return clone;
    };
    drawings.forEach(drawing => {
      drawingList.append(makeItem(drawing));
    });

  });
